<template>
  <div class="blog-project-box">
    <div class="blog-project-item animate__animated animate__backInDown">
      <!-- <div class="list-item" v-for="item in 8" :key="item"> -->
      <div class="blog-item-title">SpringMVC-什么是mvc</div>
      <div class="blog-item-content">
        <img src="@/assets/imgs/avatar.jpg" class="blog-item-avatar" alt="" />
        <div class="blog-item-info">
          MVC是模型(Model)、视图(View)、控制器(Controller)的简写，是一种软件架构模式。
          它通过将业务逻辑、页面控制、显示视图分离的方法来组织代码。
          主要作用是降低了视图与业务逻辑间的双向偶合。
          它不是一种设计模式，而是一种架构模式。当然不同的MVC存在差异。
        </div>
      </div>
      <div class="blog-author">
        <span class="blog-author-name">程序员小陈</span>
        <span class="blog-time">2024-06-21 13:50</span>
        <span class="blog-view" @click="giveUp">
          <!-- <el-icon>
            <Pointer :color="color" />
          </el-icon> -->
          <!-- <span class="view-num" :style="{ color: color }">{{
            giveUpNumber
          }}</span> -->
        </span>
        <span class="blog-view">
          <el-icon>
            <View />
          </el-icon>
          <span class="view-num">1000</span>
        </span>
        <!-- </div> -->
      </div>
    </div>

    <div class="blog-project-item animate__animated animate__backInDown">
      <!-- <div class="list-item" v-for="item in 8" :key="item"> -->
      <div class="blog-item-title">什么是Git</div>
      <div class="blog-item-content">
        <img src="@/assets/imgs/avatar.jpg" class="blog-item-avatar" alt="" />
        <div class="blog-item-info">
          Git（读音为/gɪt/）是一个开源的分布式版本控制系统，可以有效、高速地处理从很小到非常大的项目版本管理。
          [1]也是Linus
          Torvalds为了帮助管理Linux内核开发而开发的一个开放源码的版本控制软件。
        </div>
      </div>
      <div class="blog-author">
        <span class="blog-author-name">程序员小陈</span>
        <span class="blog-time">2024-06-26 13:50</span>
        <span class="blog-view" @click="giveUp">
          <!-- <el-icon>
            <Pointer :color="color" />
          </el-icon> -->
          <!-- <span class="view-num" :style="{ color: color }">{{
            giveUpNumber
          }}</span> -->
        </span>
        <span class="blog-view">
          <el-icon>
            <View />
          </el-icon>
          <span class="view-num">1000</span>
        </span>
        <!-- </div> -->
      </div>
    </div>

    <div class="blog-project-item animate__animated animate__backInDown">
      <!-- <div class="list-item" v-for="item in 8" :key="item"> -->
      <div class="blog-item-title">什么是Maven</div>
      <div class="blog-item-content">
        <img src="@/assets/imgs/avatar.jpg" class="blog-item-avatar" alt="" />
        <div class="blog-item-info">
          Maven项目对象模型(POM)，可以通过一小段描述信息来管理项目的构建，报告和文档的项目管理工具软件。
          Maven 除了以程序构建能力为特色之外，还提供高级项目管理工具。由于 Maven
          的缺省构建规则有较高的可重用性，所以常常用两三行 Maven
          构建脚本就可以构建简单的项目。由于 Maven 的面向项目的方法，许多 Apache
          Jakarta 项目发文时使用 Maven，而且公司项目采用 Maven
          的比例在持续增长。
        </div>
      </div>
      <div class="blog-author">
        <span class="blog-author-name">程序员小陈</span>
        <span class="blog-time">2024-06-26 13:50</span>
        <span class="blog-view" @click="giveUp">
          <!-- <el-icon>
            <Pointer :color="color" />
          </el-icon> -->
          <!-- <span class="view-num" :style="{ color: color }">{{
            giveUpNumber
          }}</span> -->
        </span>
        <span class="blog-view">
          <el-icon>
            <View />
          </el-icon>
          <span class="view-num">1000</span>
        </span>
        <!-- </div> -->
      </div>
    </div>

    <div class="blog-project-item animate__animated animate__backInDown">
      <!-- <div class="list-item" v-for="item in 8" :key="item"> -->
      <div class="blog-item-title">什么是Vue</div>
      <div class="blog-item-content">
        <img src="@/assets/imgs/avatar.jpg" class="blog-item-avatar" alt="" />
        <div class="blog-item-info">
          Vue (发音为 /vjuː/，类似 view) 是一款用于构建用户界面的 JavaScript
          框架。它基于标准 HTML、CSS 和 JavaScript
          构建，并提供了一套声明式的、组件化的编程模型，帮助你高效地开发用户界面。无论是简单还是复杂的界面，Vue
          都可以胜任。
        </div>
      </div>
      <div class="blog-author">
        <span class="blog-author-name">程序员小陈</span>
        <span class="blog-time">2024-06-28 13:50</span>
        <span class="blog-view" @click="giveUp">
          <!-- <el-icon>
            <Pointer :color="color" />
          </el-icon> -->
          <!-- <span class="view-num" :style="{ color: color }">{{
            giveUpNumber
          }}</span> -->
        </span>
        <span class="blog-view">
          <el-icon>
            <View />
          </el-icon>
          <span class="view-num">1000</span>
        </span>
        <!-- </div> -->
      </div>
    </div>

    <div class="blog-project-item animate__animated animate__backInDown">
      <!-- <div class="list-item" v-for="item in 8" :key="item"> -->
      <div class="blog-item-title">JAVA的优点</div>
      <div class="blog-item-content">
        <img src="@/assets/imgs/avatar.jpg" class="blog-item-avatar" alt="" />
        <div class="blog-item-info">
          具有较高的安全性
          Java语言在安全性方面表现出色，这主要得益于其设计时的细致考虑和众多安全特性。首先，Java避免了使用显示指针，这减少了安全攻击和风险，因为指针可能被用于未经授权的内存访问。其次，Java的安全管理器可以为每个应用定义存取规则，进一步增强了安全性。尽管Java曾被发现存在一些安全隐患，但开发组对此非常重视，会立即修复这些问题。此外，由于Java解释器的细节是开放的，有助于各界力量共同发现和防范安全隐患。
        </div>
      </div>
      <div class="blog-author">
        <span class="blog-author-name">程序员小陈</span>
        <span class="blog-time">2024-06-28 13:50</span>
        <span class="blog-view" @click="giveUp">
          <!-- <el-icon>
            <Pointer :color="color" />
          </el-icon> -->
          <!-- <span class="view-num" :style="{ color: color }">{{
            giveUpNumber
          }}</span> -->
        </span>
        <span class="blog-view">
          <el-icon>
            <View />
          </el-icon>
          <span class="view-num">1000</span>
        </span>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
let color = ref<string>("#333");
let giveUpNumber = ref<number>(100);
let giveUp = () => {
  color.value = "#f40";
  giveUpNumber.value++;
};
</script>

<style lang="scss" scoped>
.blog-project-box {
  width: 100%;
  height: 700px;
  // overflow-y: auto;
  // background-color: #ffa;
  border-radius: 20px;
  margin-top: 70px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 30px;
}

.blog-project-item {
  width: 100%;
  height: 200px;
  border-radius: 20px;
  background-color: #ffe;
  margin-bottom: 20px;
}

.blog-project-item {
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #efefef;
  padding: 20px;
}

.blog-item-title {
  font-size: 16px;
  font-weight: 600;
  color: #005dae;
  //  cursor: pointer;
  //  text-decoration: underline;
}

.blog-item-title:hover {
  // color: red;
}
.blog-item-avatar {
  width: 50px;
  height: 50px;
}

.blog-item-content {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;

  img {
    margin: 3px 5px 0 0;
  }
}

.blog-item-info {
  font-size: 12px;
  color: #555;
  line-height: 20px;
}

.blog-author {
  display: flex;
  font-size: 13px;

  span {
    margin-right: 20px;
  }
}

.blog-author-name {
  color: #005dae;
}

.blog-view {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>