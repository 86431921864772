import { createRouter, createWebHistory } from 'vue-router'
import myIndex from './view/myIndex/myIndex.vue'
// import blogIndex from './view/blogIndex/blogList.vue'
// import blogInfo from './view/myIndex/components/blogInfo.vue'

const routes = [
  {
    path: '/',
    name: 'blogIndex',
    component: myIndex
  },
  {
    path: '/myIndex',
    name: 'myIndex',
    component: myIndex,
    // children: [
    //   {
    //     path: '/blogInfo',
    //     name: 'blogInfo',
    //     component: blogInfo
    //   }
    // ]
  }


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
