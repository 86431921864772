<template>
  <div class="blog-info-box animate__animated animate__rotateInDownLeft">
    <div
      class="blog-info-left-box"
      style="
        line-height: 1.2em;
        font-size: 5em;
        font-weight: 800;
        text-shadow: 20px 20px 20px #537e90;
      "
    >
      成功者绝不放弃
    </div>
    <div class="blog-info-right-box">
      作为一名多年经验丰富的Java程序员，拥有深厚的编程功底和广泛的项目经验。我热爱编程，对Java技术有着深刻的理解和独到的见解。在我的编程世界里，代码是艺术，每一行代码都是我思想的延伸，是我对技术的探索和表达。
      我擅长使用Java语言开发高效、稳定且可扩展的应用程序。我熟练掌握Java的各种框架和工具，如Spring、Hibernate、Maven等，能够灵活运用它们解决各种复杂的业务问题。我对面向对象编程有着深入的理解，能够设计出结构清晰、易于维护的代码架构。
      在项目开发中，我注重团队合作，善于沟通和协调，能够与产品经理、设计师和测试人员紧密合作，共同推动项目的顺利进行。我善于分析问题、解决问题，能够快速定位并修复bug，保证项目按时交付。
      除了技术能力，我还注重个人素养的提升。我积极学习新知识，不断追求进步，保持对技术的热情和好奇心。我善于总结经验、分享经验，乐于帮助他人成长。我具有良好的时间管理能力和抗压能力，能够在高强度的工作环境下保持高效率和高质量的工作。
      总的来说，作为一名Java程序员，我不仅具备扎实的技术功底和丰富的项目经验，更重要的是我热爱编程，对技术充满激情，愿意不断学习和探索。我相信，通过我的努力和不懈的追求，我能够在Java领域取得更大的成就，为公司和团队创造更大的价值。
      希望这份自我介绍能够展现出您作为一名Java程序员的优秀品质和能力。如果您需要进一步完善或调整，欢迎告诉我，我将竭诚为您提供帮助
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.blog-info-box {
  width: 100%;
  height: 700px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.blog-info-left-box {
  color: aliceblue;
  font-weight: 180px;
  color: white;
  line-height: 56px;
  width: 300px;
  height: 600px;
  display: inline-flex;
  align-content: center;
  padding: 30px;

  size: 80px;
  justify-content: center;

  background-color: #6abbb6;
  // background-color: #496078;
  border-radius: 10px;
}

.blog-info-right-box {
  font-weight: 46px;
  text-indent: 4em;
  color: white;
  line-height: 32px;
  display: inline-flex;
  align-content: center;
  justify-content: center;

  padding: 30px;
  width: 800px;
  height: 600px;
  background-color: #496078;
  // background-color: #6abbb6;

  border-radius: 10px;
}
</style>