<template>
  <div class="blog-Box">
    <ul class="card-list animate__animated animate__fadeIn">
      <li
        v-for="(item, index) in cardList"
        class="pointer"
        :key="index"
        :class="
          currentIndex == index
            ? 'active animate__animated animate__rubberBand'
            : ''
        "
        @click="changeIndex(index)"
      >
        {{ item }}
      </li>
    </ul>
    <tab-view :currentIndex="currentIndex" />
    <div
      style="
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px;
      "
    >
      <div class="line"></div>
      <!-- <div>友情链接： 链接一 链接二 链接三 链接四 链接五 链接六</div> -->
      <a
        style="color: white"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        >粤ICP备20009587号</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import tabView from "./components/tabView.vue";
let currentIndex = ref<number>(0);
let cardList = ref<string[]>(["首页", "个人简介", "作品展示"]);
let changeIndex = (index: number) => {
  currentIndex.value = index;
};
</script>

<style lang="scss" scoped>
.blog-Box {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(130deg, #495f77, #6bbeb8);
  overflow-x: hidden;
  overflow-y: auto;

  .card-list {
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 800px;
    height: 70px;
    margin: 30px auto;
    font-weight: 600;
    color: #ffe;
    background-color: #6bbeb8;
    border-radius: 50px;
  }

  .pointer {
    padding: 8px 20px;
  }

  .active {
    background: #fff;
    padding: 8px 20px;
    border-radius: 10px;
    color: #6bbeb8;
  }
}
</style>