<template>
    <div class="blog-music-box animate__animated animate__swing">
        <div class="blog-music-header ">
            <ColdDrink style="width: 1.5em; height: 1.5em; margin-right: 8px;color: #fff;" /> 音乐汇
        </div>
        <div class="blog-music-content">
            <div class="blog-music-left-list">
                <ul>
                    <li v-for="(item, index) in leftList" class="pointer" :key="index"
                        :class="currentIndex == index ? 'active' : ''" @click="currentIndex = index">{{ item }}</li>
                </ul>

            </div>
            <div class="blog-music-right-list">
                <ul>
                    <li v-for="(item, index) in rightList" class="pointer" :key="index"
                        :class="currentRightIndex == index ? 'active' : ''" @click="currentRightIndex = index">{{ item
                        }}</li>
                </ul>
                <el-carousel :interval="4000" type="card" height="200px">
                    <el-carousel-item v-for="item in 6" :key="item">
                        <h3 text="2xl" justify="center">{{ item }}</h3>
                    </el-carousel-item>
                </el-carousel>
                <p>推荐歌单</p>
                <div class="music-list">
                    <div v-for="item in 5" :key="item" class="music-item"></div>
                </div>

            </div>
        </div>
        <div class="blog-music-bottom"></div>

    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
let leftList = ref<string[]>(['发现音乐', '播客', '视频', '关注', '直播', '私人漫游'])
let rightList = ref<string[]>(['个人推荐', '专属定制', '歌单', '排名榜', '歌手', '最新音乐'])
let currentIndex = ref<number>(0)
let currentRightIndex = ref<number>(0)

</script>

<style lang="scss" scoped>
.blog-music-box {
    width: 100%;
    height: 700px;
    background-color: #fff;
    margin-top: 70px;

    .blog-music-header {
        width: 100%;
        height: 60px;
        background-color: #6bbeb8;
        display: flex;
        align-items: center;
        padding-left: 50px;
        font-size: 20px;
        color: #fff;
        box-sizing: border-box;
    }

    .blog-music-content {
        display: flex;
        height: 570px;

    }

    .blog-music-left-list {
        width: 200px;
        height: 100%;
        // background-color: #ffa;
        box-sizing: border-box;
        border-right: 1px solid #ccc;
        padding: 10px 0;
        padding-left: 20px;
        padding-right: 5px;

        ul {
            width: 100%;
        }

        li {
            line-height: 38px;
            font-size: 15px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 5px;
            margin-bottom: 2px;

        }

        li:hover {
            background-color: #f6f6f7;

        }

        .active {
            background-color: #f6f6f7;
            font-weight: 600;
            font-size: 17px;
        }
    }

    .blog-music-right-list {
        flex: 1;
        padding: 20px 50px;
        ul {
            display: flex;
            font-size: 18px;
            margin-bottom: 30px;
            // position: fixed;
            // z-index: 30;
            // flex: 1;
            // background-color: #fff;
        }

        li {
            margin-right: 20px;
        }
        p{
            font-size: 20px;
            font-weight: 600;
            margin: 20px 0;
        }

        .active {
            font-weight: 600;
        }

        .music-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .music-item{
                width: 150px;
                height: 150px;
                background-color: #6bbeb8;
                border-radius: 6px;
                margin-right: 20px;
            }
        }  
    }

    .blog-music-bottom {
        height: 70px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border-top: 1px solid #ccc;
    }
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>