<template>
    <div class="tab-view-box">
        <blog-index v-if="props.currentIndex == 0" />
        <blog-info v-if="props.currentIndex == 1" />
        <blog-project v-if="props.currentIndex == 2" />
        <blog-contact v-if="props.currentIndex == 3" />
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import blogIndex from './blogIndex.vue';
import blogInfo from './blogInfo.vue';
import blogProject from './blogProject.vue';
import blogContact from './blogContact.vue';
let props = defineProps({
    currentIndex: Number
})
</script>

<style lang="scss" scoped>
.tab-view-box {
    max-width: 1200px;
    height: 700px;
    // background-color: #fff;

    margin: 0 auto;
}
</style>