<template>
  <div class="tab-view-box">
    <img
      src="@/assets/imgs/avatar.jpg"
      alt=""
      class="avatarImg pointer"
      :style="{
        width: 500 + 'px',
        height: 500 + 'px',
      }"
    />
    <div class="blog-user animate__animated animate__slideInLeft">
      {{ name }}
    </div>
    <div class="blog-user work-info animate__animated animate__fadeIn">
      四年+ Java攻城狮
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
let name = ref<string>("个人学习测试");
let circleWidth = ref<number>(300);
onMounted(() => {
  window.addEventListener("mousewheel", handleScroll);
});
let handleScroll = (e: any) => {
  console.log(e.deltaY > 0);
  if (e.deltaY > 0) {
    circleWidth.value += 10;
  } else {
    circleWidth.value -= 10;
  }
};
</script>

<style lang="scss" scoped>
.avatarImg {
  overflow: hidden;
  border-radius: 50%;
  display: block;
  transition: all 1s linear;
  margin: 70px auto;
}

.avatarImg:hover {
  animation: spin 5s linear infinite;
}

.blog-user {
  text-align: center;
  font-size: 60px;
  color: #fff;
  margin-top: 30px;
  font-family: "Roboto", sans-serif;
  text-shadow: 10px 10px 4px rgba(0, 0, 0, 0.3);
}

.work-info {
  font-size: 40px;
  color: beige;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>